<template>
  <v-card outlined class="pa-4 font-weight-bold">
    <v-card-title class="font-weight-bold"
      >Sales Trend and Model Fitting</v-card-title
    >
    <MultiLine :chartData="chartData" :options="options"></MultiLine>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "SalesTrendModelFit",
  data: () => ({
    buffer: 0.2,
  }),
  components: {
    MultiLine: () => import("@/components/chart/MultiLine"),
  },
  computed: {
    chartData() {
      return this.$store.getters.summaryLineChartData;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      const gridlines_color = this.$vuetify.theme.dark
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: fontColor,
          },
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                parser: (value) => {
                  return moment(value, "YYYYMMDD");
                },
                unit: "month",
                unitStepSize: 1,
                displayFormats: {
                  month: "YYYY/MM/DD",
                },
              },
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              ticks: {
                fontColor: fontColor,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("date"),
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: gridlines_color,
                zeroLineColor: gridlines_color,
              },
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("sales"),
              },
              ticks: {
                beginAtZero: false,
                fontColor: fontColor,
                callback: function(value) {
                  if (parseInt(value) >= 1000) {
                    return (
                      "$" +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    );
                  } else {
                    return "$" + value;
                  }
                },
                suggestedMin: this.suggestedMin,
                suggestedMax: this.suggestedMax,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0,
          },
        },
      };
    },
    suggestedMin() {
      if (this.chartData) {
        const data = [];
        this.chartData.datasets.forEach((dataset) => {
          data.push(...dataset.data);
        });
        const average = data.reduce((a, b) => a + b, 0) / data.length;
        const min = average * (1 - this.buffer);
        return min;
      } else {
        return null;
      }
    },
    suggestedMax() {
      if (this.chartData) {
        const data = [];
        this.chartData.datasets.forEach((dataset) => {
          data.push(...dataset.data);
        });
        const average = data.reduce((a, b) => a + b, 0) / data.length;
        const max = average * (1 + this.buffer);
        return max;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
